import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useGlobalState } from "www/shared/modules/global_context";
import { classNames } from "www/shared/utils";
import { indianDomain } from "www/shared/utils/handleDomains";
import NavMenu from "./NavMenu";

export default function HomeHeader({
  setShow,
}: {
  setShow: (show: boolean) => void;
}) {
  const { supabaseUser } = useGlobalState();
  const isUserLoggedIn = !!supabaseUser;
  const router = useRouter();
  const currentDomain = typeof window !== "undefined" && window.location.host;

  return (
    <div
      className={classNames(
        router.asPath.includes("/sponsors") ||
          router.asPath.includes("/investors")
          ? "bg-transparent absolute left-0 right-0"
          : "bg-white",
        "flex justify-between w-full px-7 py-5 xl:px-24 lg:px-20 md:px-10 items-center"
      )}
    >
      <div className="flex items-center">
        {currentDomain == indianDomain ? (
          <>
            <div>
              <Image
                className="cursor-pointer "
                width={150}
                height={50}
                src="/logoIn.svg"
                alt="Logo"
              />
            </div>
          </>
        ) : (
          <div className="flex flex-row items-center gap-x-12">
            <Link className="flex items-center gap-x-2" href="/">
              <Image
                className="cursor-pointer mr-2"
                height={32}
                width={32}
                src="/logo_v2.svg"
                alt="Logo"
              />
              <p className="font-inter font-semibold text-2xl text-green-700">
                ELMBASE
              </p>
            </Link>
            <NavMenu />
          </div>
        )}
      </div>
      <button
        className="text-green-700 text-base leading-6 font-semibold p-2 hover:text-green-600 border-none"
        onClick={() => {
          if (!isUserLoggedIn) {
            setShow(true);
          } else {
            router.push("/feed");
          }
        }}
      >
        {isUserLoggedIn ? (
          <div>
            Go to app <FontAwesomeIcon icon={faArrowRight} />
          </div>
        ) : (
          "Login"
        )}
      </button>
    </div>
  );
}
