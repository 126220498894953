import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import Link from "next/link";
import { Fragment } from "react";

const nav = {
  Sponsors: {
    links: [
      {
        title: "Why Elmbase?",
        link: "/sponsors",
      },
      {
        title: "Key features",
        link: "/sponsors#features",
      },
      {
        title: "Book a demo",
        link: "/sponsors#demo",
      },
      {
        title: "Pricing",
        link: "/sponsors#pricing",
      },
    ],
  },
  Investors: {
    links: [
      {
        title: "Join waitlist",
        link: "/investors",
      },
      {
        title: "Personal dashboard",
        link: "/investors#features",
      },
      {
        title: "Grow your network",
        link: "/investors#network",
      },
    ],
  },
};

const NavMenu = () => {
  return (
    <nav
      className={classNames(
        "absolute sm:relative left-0 right-0 top-[76px] sm:top-0 z-50 w-fit mx-auto sm:mx-0 hidden md:block"
      )}
    >
      <ul className="flex items-center gap-x-5">
        {Object.entries(nav).map(([navItem, { links }]) => (
          <Menu as={Fragment} key={navItem}>
            <div className="relative z-50">
              <li>
                <Menu.Button
                  className={classNames(
                    "flex items-center gap-x-[10px] py-1 px-2 text-sm rounded-lg",
                    "text-gray-700 hover:text-green-700"
                  )}
                >
                  {({ open }) => (
                    <>
                      {navItem}
                      <FontAwesomeIcon
                        className={classNames(
                          "text-xs mt-[1px] group-hover:translate-x-1 transition-transform",
                          open ? "rotate-180" : ""
                        )}
                        icon={faChevronDown}
                      />
                    </>
                  )}
                </Menu.Button>
              </li>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-56 flex flex-col bg-white border border-gray-200 rounded-lg shadow-sm absolute left-0 top-[calc(100%_+_10px)]">
                  <div className="p-2 rounded-lg">
                    {links.map((item) => (
                      <Menu.Item key={navItem + item}>
                        <Link
                          className={classNames(
                            "block px-4 py-2 text-sm rounded-lg",
                            "hover:bg-gray-100"
                          )}
                          href={item.link}
                        >
                          {item.title}
                        </Link>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          </Menu>
        ))}
      </ul>
    </nav>
  );
};

export default NavMenu;
