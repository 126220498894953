import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/pro-thin-svg-icons";
import SlideOver from "www/shared/components/slide_over/SlideOver";
import styles from "./Home.module.css";
import cx from "classnames";
import { useRouter } from "next/router";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { useGlobalState } from "www/shared/modules/global_context";
import { toast } from "react-toastify";
import { queryTypes, useQueryState } from "next-usequerystate";
import Image from "next/image";
import validator from "validator";
import { Database } from "supabase/generated/types";
interface ProviderButtonProps {
  provider: string;
  onClick: () => void;
  iconPath: string;
  disabled: boolean;
}
interface AuthPageProps {
  show: boolean | null;
  setShow: (show: boolean) => void;
}
export default function Auth({ show, setShow }: AuthPageProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useQueryState(
    "forgotPassword",
    queryTypes.boolean.withDefault(false)
  );
  const modifiedSetShow = (show: boolean) => {
    if (show === false) {
      setShowForgotPassword(null);
    }
    setShow(show);
  };
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();
  const clientSupabase = createClientComponentClient<Database>();
  const setGlobalState = useGlobalState((s) => s.setGlobalState);
  const [redirectTo] = useQueryState(
    "redirectTo",
    queryTypes.string.withDefault("/feed")
  );

  const setGlobalStateSupabaseUser = async () => {
    const {
      data: { session },
    } = await clientSupabase.auth.getSession();
    setGlobalState({ supabaseUser: session?.user });
    router.push(redirectTo);
  };

  const loginEmailPassword = async () => {
    setError(null);
    const res = await clientSupabase.auth.signInWithPassword({
      email,
      password,
    });
    if (res.error !== null) {
      let errorMessage = res.error.message;
      if (errorMessage === "Email not confirmed") {
        errorMessage = "Account pending approval";
      }
      setError(errorMessage);
      return;
    }
    await setGlobalStateSupabaseUser();
  };

  const forgotPassword = async () => {
    setError(null);
    if (!email || !validator.isEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    setLoading(true);

    const res = await clientSupabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/account`,
    });
    if (res.error !== null) {
      setError(res.error.message);
      setLoading(false);
      return;
    }
    toast.success("Password reset email sent");
    setLoading(false);
    setShowForgotPassword(false);
  };

  async function signInWithGoogle() {
    console.log("signing in with google");
    const { data, error } = await clientSupabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: `${window.location.origin}${redirectTo}`,
      },
    });
    if (error) {
      toast.error("Error logging in with Google, please try again.");
      console.log(error.message);
    }
    console.log(data, error);
  }

  async function signInWithLinkedin() {
    const { error } = await clientSupabase.auth.signInWithOAuth({
      provider: "linkedin",
      options: {
        redirectTo: window.location.origin + redirectTo,
      },
    });
    if (error) {
      toast.error("Error logging in with Google, please try again.");
      console.log(error.message);
    }
    await setGlobalStateSupabaseUser();
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginEmailPassword();
    }
  };

  return (
    <SlideOver show={show ?? false} setShow={modifiedSetShow}>
      <div className="h-full flex items-center justify-center flex-col bg-white w-full">
        <div className="max-w-[350px] w-full ">
          {showForgotPassword ? (
            <>
              <p className="font-inter font-medium text-[32px] leading-[39px] text-green-700 mb-6 text-center">
                Forgot Password
              </p>
              <p className="text-sm leading-5 font-normal font-inter text-gray-600 mb-6 ">
                Please enter your user account&apos;s email address. If there is
                an account associated with that email, we will send you password
                reset instructions.
              </p>
              <div className="w-full space-y-6">
                <div className="w-full">
                  <div className="mt-1 flex rounded-md shadow-sm w-full">
                    <div className="relative rounded-md shadow-sm w-full">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-gray-300 px-4 py-[21.5px] focus:border-green-500 focus:ring-green-500 sm:text-sm h-[60px] font-light text-sm leading-[17px] text-[#828B93] bg-white "
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="bg-[#15803D] font-inter font-normal text-sm leading-[150%] text-white py-[19.5px] flex gap-2 items-center justify-center border border-[#D1DBE3]  rounded-[6px] shadow-[0px_10px_20px_rgba(0,0,0,0.2)] w-full max-w-[350px] mx-auto mt-6 hover:bg-green-800 disabled:opacity-20 disabled:cursor-not-allowed"
                disabled={loading}
                onClick={forgotPassword}
              >
                Send password reset link
              </button>
            </>
          ) : (
            <>
              <p className="font-inter font-medium text-[32px] leading-[39px] text-green-700 mb-12 text-center">
                Sign In
              </p>
              <div className="flex flex-col  w-full justify-center space-y-4 ">
                <ProviderButton
                  iconPath="/google.svg"
                  provider="Continue with Google"
                  onClick={signInWithGoogle}
                  disabled={false}
                />
                <ProviderButton
                  iconPath="/linkedin.svg"
                  provider="Continue with Linkedin"
                  onClick={signInWithLinkedin}
                  disabled={true}
                />
              </div>
              <div className="relative w-full flex items-center my-4">
                <div className="h-[1px] flex-1 bg-gray-200 " />
                <span className="text-gray-700 font-medium z-2 bg-white px-2 ">
                  OR
                </span>
                <div className="h-[1px] flex-1 bg-gray-200 " />
              </div>
              {!showLoginForm && (
                <p
                  className="font-inter font-medium text-base leading-[39px] text-[#15803D] text-center cursor-pointer"
                  onClick={() => {
                    setShowLoginForm(!showLoginForm);
                  }}
                >
                  Sign In with Email Address
                </p>
              )}
              {showLoginForm && (
                <div
                  className={cx(
                    "relative rounded-md shadow-sm w-full",
                    styles.loginForm
                  )}
                >
                  <div className="w-full space-y-6">
                    <div className="w-full">
                      <div className="mt-1 flex rounded-md shadow-sm w-full">
                        <div className="relative rounded-md shadow-sm w-full">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-gray-300 px-4 py-[21.5px] focus:border-green-500 focus:ring-green-500 sm:text-sm h-[60px] font-light text-sm leading-[17px] text-[#828B93] bg-white "
                            placeholder="Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="mt-1 flex rounded-md shadow-sm w-full">
                        <div className="relative rounded-md shadow-sm w-full">
                          <input
                            onKeyDown={handleKeyDown}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            className="block w-full rounded-md border-gray-300 pl-4 pr-10 py-[21.5px] focus:border-green-500 focus:ring-green-500 sm:text-sm h-[60px] font-light text-sm leading-[17px] text-[#828B93] bg-white  autofill:bg-white "
                            placeholder="Your Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                              className="h-6 w-5 text-green-700 font-light text-[19.2px] leading-[19px]"
                              aria-hidden="true"
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="text-green-700 text-right w-full block"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      Forgot Password?
                    </button>
                  </div>
                  <button
                    className="bg-[#15803D] font-inter font-normal text-sm leading-[150%] text-white py-[19.5px] flex gap-2 items-center justify-center border border-[#D1DBE3]  rounded-[6px] shadow-[0px_10px_20px_rgba(0,0,0,0.2)] w-full max-w-[350px] mx-auto mt-6 hover:bg-green-800"
                    onClick={loginEmailPassword}
                  >
                    Sign In
                  </button>
                </div>
              )}
            </>
          )}
          {error && <p className="text-red-600 mt-3">{error}</p>}
        </div>
      </div>
    </SlideOver>
  );
}

const ProviderButton = ({
  provider,
  onClick,
  iconPath,
  disabled,
}: ProviderButtonProps) => {
  return (
    <button
      className={
        `font-inter font-normal text-sm leading-[150%] text-[#353636] py-[19.5px] flex gap-2 items-center justify-center border border-[#D1DBE3]  rounded-[6px] w-full max-w-[350px] mx-auto ` +
        (disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white ")
      }
      onClick={onClick}
      disabled={disabled}
    >
      <Image src={iconPath} alt="linkedin" height={24} width={24} />
      {provider}
    </button>
  );
};
